export const COLUMN_MAPPING = {
  applno: 'Appplication Number',
  submission_type: 'Submission Type',
  submission_num: 'Submission Number',
  generic_name: 'Generic Name',
  sponsor_name: 'Sponsor Name',
  product_name: 'Product Name',
  indiication: 'Disease Name',
  approval_date: 'Approval Date',
  approval_time: 'Approval Time',
  changed_disease_types: 'Changed Disease Types',
  therapeutic_categories: 'Therapeutic Use Categories (Across All Indications)',
  indication_added: 'Indication Added',
  indication_change: 'Indication Change',
  indications_text: 'Indication Text',
  label: 'Label',
  value: 'Value'
};

export const INDICATION_CHANGE = 'indication_change';

export const INDICATION_STYLE: { [key: string]: string } = {
  indication_change: '<span style="font-size: 13px; font-weight: 700; color: #616161;">$1</span>',
  indication_text: '<p style="font-size: 13px; font-weight: 700; color: #616161;">$1</p>'
};

export const IGNORE_FIELDS = ['id'];

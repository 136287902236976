import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import ApplicationCard from '../../../components/ApplicationCard';
import styles from './styles/IndicationChangeTimelineView.styles';
import IndicationChangeTimelineView from './IndicationChangeTimelineView';
import { UrlParameters } from '../../../types/reactRouterDom';
import getIndicationChangeData from './getIndicationChangeData';
import { DataByYear } from './type';
import FullScreenProcessing from '../../SpecialityDesignation/components/FullScreenProcessing';
import DownloadIcon from '../../../assets/svgs/Icons/DownloadIcon';
import { handleIndicationChangeCSVDownload } from './utils';

const IndicationChangeVisualize = () => {
  const { regulatoryState } = useContext(RegulatoryInformationStore);
  const { number, source } = useParams<UrlParameters>();
  const [indicationList, setIndicationList] = useState<Array<string>>([]);
  const [dataByYear, setDataByYear] = useState<DataByYear>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [scrollRefElement, setScrollRefElement] = useState<any>(null);
  const [downloadResults, setDownloadResults] = useState<{ [key: string]: string | any }>({});

  const getSubmissionData = (submission: string) => {
    // Split submission string into type and number
    const [submissionType, submissionNum] = submission.split('-');

    // Find and return the matching dictionary
    return regulatoryState?.approvalHistory?.find(
      item => item.submission_type === submissionType && item.submission_num === submissionNum
    );
  };

  const getIndicationData = async () => {
    setLoading(true);
    const indicationData = await getIndicationChangeData(
      source as string,
      number as string,
      regulatoryState.appliedFilters
    );
    const indicationAddedData: any = indicationData?.indications_added;

    const allResult: Array<any> = [];
    if (Object.keys(indicationData).length !== 0) {
      // sort by order field
      const sortedKeys = Object.keys(indicationData?.data).sort(
        (a, b) => indicationData.data[a].order - indicationData.data[b].order
      );
      setIndicationList(sortedKeys);
      const dataCategoriesByYear: any = {};

      Object.entries(indicationData?.data).forEach(
        ([indication, submissionData]: [string, any]) => {
          // Find the entry with the earliest approval year for the indication
          const firstApprovedSubmission =
            submissionData?.approval_data?.[submissionData.approval_data.length - 1];
          const aaprovalYear = firstApprovedSubmission?.approval_year;
          const approvalSubmissionData = getSubmissionData(firstApprovedSubmission?.submission);

          submissionData?.approval_data.forEach(
            (entry: { submission: string; approval_date: string; approval_year: string }) => {
              if (indicationAddedData[entry?.submission].length !== 0) {
                const year = entry.approval_year;
                const supplementData = getSubmissionData(entry?.submission);

                // Initialize year and indication data if not present
                if (!dataCategoriesByYear[year]) {
                  dataCategoriesByYear[year] = { approval_date: {}, indication_data: {} };
                }
                if (!dataCategoriesByYear[aaprovalYear]) {
                  dataCategoriesByYear[aaprovalYear] = { approval_date: {}, indication_data: {} };
                }

                // Store the earliest entry under approval_date
                if (!dataCategoriesByYear[aaprovalYear].approval_date[indication]) {
                  dataCategoriesByYear[aaprovalYear].approval_date[indication] = {
                    ...firstApprovedSubmission,
                    ...approvalSubmissionData
                  };
                }

                // Add the entry to indication_data for each year and indication
                if (!dataCategoriesByYear[year].indication_data[indication]) {
                  dataCategoriesByYear[year].indication_data[indication] = [];
                }
                dataCategoriesByYear[year].indication_data[indication].push({
                  ...entry,
                  ...supplementData,
                  indication_added: indicationAddedData[entry?.submission]
                });
                allResult.push({
                  ...entry,
                  ...supplementData,
                  indication_added: indicationAddedData[entry?.submission],
                  indication,
                  applno: number
                });
              }
            }
          );
        }
      );
      setDataByYear(dataCategoriesByYear);
      setDownloadResults(allResult);
    }
    setLoading(false);
  };

  useEffect(() => {
    getIndicationData();
  }, []);

  if (loading) {
    return (
      <FullScreenProcessing
        loading={loading}
        open={loading}
        message='Generating Timeline View...'
      />
    );
  }

  const downloadImage = async () => {
    const fileName = `indication-change-timeline-${regulatoryState?.applicationDetails?.product_name}`;

    const element = scrollRefElement?.current;
    if (!element) return;

    setImageLoading(true);
    try {
      const { default: html2canvas } = await import('html2canvas');
      const canvas = await html2canvas(element, {
        scale: 1,
        scrollY: 0,
        scrollX: 0,
        width: element.scrollWidth,
        height: element.scrollHeight
      });
      const link = document.createElement('a');
      link.download = fileName;
      link.href = canvas.toDataURL();
      link.click();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setImageLoading(false);
    }
  };

  return (
    <Box width='100%' height='100%'>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <ApplicationCard data={regulatoryState?.applicationDetails} />
        {!loading && Object.keys(dataByYear || {}).length !== 0 && (
          <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
            <Tooltip title='Download CSV data'>
              <DownloadIcon
                onClick={() =>
                  handleIndicationChangeCSVDownload(
                    downloadResults,
                    regulatoryState?.applicationDetails?.product_name ?? ''
                  )
                }
                sx={styles.buttonIcon}
              />
            </Tooltip>
            <Tooltip title={imageLoading ? 'Downloading...' : 'Save as png'}>
              {imageLoading ? (
                <CircularProgress color='success' size={14} />
              ) : (
                <CameraAltIcon onClick={downloadImage} sx={styles.downloadbuttonIcon} />
              )}
            </Tooltip>
          </Stack>
        )}
      </Stack>
      {!loading && Object.keys(dataByYear || {}).length !== 0 ? (
        <Grid container mb={3} mt={2} flexWrap='nowrap' sx={styles.gridContainer}>
          <IndicationChangeTimelineView
            indicationList={indicationList}
            dataByYear={dataByYear}
            source={source}
            setScrollRefElement={setScrollRefElement}
          />
        </Grid>
      ) : (
        <Box sx={styles.message}>
          <Typography variant='h6' color='inherit'>
            No Data Available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(IndicationChangeVisualize);

import Papa from 'papaparse';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { getFileName } from '../../../helpers/getFileName';
import getFormatDataForCSV from '../../ResultsPage/utils/getFormatDataForCSV';
import { COLUMN_MAPPING, IGNORE_FIELDS, INDICATION_STYLE } from './constant';

const createDataEntry = (
  id: string,
  title: string,
  value: any,
  submissionNumber = '',
  dataformat = 'string'
) => ({
  id,
  title,
  value: Array.isArray(value) && dataformat === 'string' ? value.join('\n') : value,
  ...(submissionNumber && { submission_number: submissionNumber }),
  dataformat
});

const getIndicatioDrawerData = (dataItem: any) => {
  if (!dataItem) return [];
  return [
    createDataEntry(
      'changed_disease_types',
      'Changed Disease Types',
      dataItem?.changed_disease_types,
      '',
      'list'
    ),
    createDataEntry(
      'indication_change',
      'Indications Changes Compared to Previous',
      dataItem?.indication_change,
      dataItem?.submission_compared
    ),

    createDataEntry(
      'therapeutic_categories',
      'Therapeutic Use Categories (Across All Indications)',
      dataItem?.therapeutic_categories?.filter((category: { value: boolean }) => category.value),
      '',
      'chips'
    ),
    createDataEntry('indication_text', 'Indications', dataItem?.indications_text)
  ];
};

const transformData = (data: any, columnMapping: any) => {
  const allowedColumns = Object.keys(columnMapping);
  return data?.map((each: any) =>
    allowedColumns.reduce((acc: any, key: any) => {
      if (key === 'therapeutic_categories') {
        acc[columnMapping[key]] = each[key]
          .filter((item: { value: boolean }) => item.value === true)
          .map((item: { label: any }) => item.label)
          .join(', ');
      } else if (each[key] !== undefined) {
        if (Array.isArray(each[key])) {
          if (typeof each[key][0] === 'object' && !Array.isArray(each[key][0])) {
            acc[columnMapping[key]] = getFormatDataForCSV(each[key], IGNORE_FIELDS);
          } else {
            acc[columnMapping[key]] = each[key].join(', ');
          }
        } else if (typeof each[key] === 'object' && !Array.isArray(each[key])) {
          acc[columnMapping[key]] = getFormatDataForCSV([each[key]], IGNORE_FIELDS);
        } else {
          acc[columnMapping[key]] = each[key];
        }
      }
      return acc;
    }, {})
  );
};

export const handleIndicationChangeCSVDownload = (data: any, searchTerm: string) => {
  const selectedColumns = COLUMN_MAPPING;
  const formattedData = transformData(data, selectedColumns);
  const csv = Papa.unparse(formattedData);
  // Create a Blob from the CSV string
  const blob = new Blob([csv], { type: 'text/csv' });
  // Create a download link and click it
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const fileName = getFileName({
    prefix: 'ria',
    module: 'search',
    postfix: `${searchTerm}_${date}`,
    additional: '',
    extension: 'csv'
  });
  a.download = `${fileName}.csv`;
  a.click();
};

export const formatText = (content: string) => {
  const convertedHtml = marked(content);
  const sanitizedHtml = DOMPurify.sanitize(convertedHtml, { USE_PROFILES: { html: true } });
  return sanitizedHtml;
};

export const formatDescription = (description: string, id: string) => {
  // Regular expression to match section numbers and titles (e.g., "1.1 Unresectable or Metastatic Melanoma")
  // Step 1: Replace **text** with <strong>text</strong>
  let formattedDescription = description.replace(/\*\*(.*?)\*\*/g, INDICATION_STYLE[id]);

  // Step 2: Bold section titles like "1.1 Title Here"
  formattedDescription = formattedDescription.replace(/^(\d+\.\d+ [^\n]+)/gm, INDICATION_STYLE[id]);

  return formatText(formattedDescription);
};

export default getIndicatioDrawerData;

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { Box, Divider, Stack, styled } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import styles from './styles/IndicationChangeDrawer.styles';
import CaretDownIcon from '../../../assets/svgs/Icons/CaretDown';
import { formatDescription } from './utils';
import { INDICATION_CHANGE } from './constant';

export interface IndicationDataProps {
  id: string;
  title: string;
  value: any;
  submission_number: string;
  dataformat: string;
}
export interface IndicationAccordionProps {
  data: IndicationDataProps;
}

const Accordion = styled((props: AccordionProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid #E0E0E0`,
  borderRadius: 12,
  marginBottom: 16,
  overflow: 'hidden',
  backgroundColor: 'gray.0',
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAccordionSummary {...props} />
))(() => ({
  flexDirection: 'row-reverse',
  minHeight: 40,
  paddingX: '16px 20px',
  '& .MuiAccordionSummary-content': {
    alignItems: 'center'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  backgroundColor: 'white'
}));

const IndicationAccordion = ({ data }: IndicationAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <Accordion expanded={isExpanded} onChange={() => setIsExpanded(prev => !prev)}>
      <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
        <CaretDownIcon
          sx={{
            ...styles.caretDownIcon,
            rotate: isExpanded ? '180deg' : '0deg'
          }}
        />
        <Typography sx={styles.accordionSummaryTitle}> {data?.title}</Typography>
        {data?.id === INDICATION_CHANGE && data?.submission_number && (
          <Box sx={styles.submissionText}>{data?.submission_number}</Box>
        )}
      </AccordionSummary>
      {isExpanded && <Divider sx={styles.divider} />}
      <AccordionDetails sx={styles.accordionDetails}>
        {data?.dataformat === 'string' && (
          <Typography
            variant='body2'
            sx={styles.accordionText}
            dangerouslySetInnerHTML={{
              __html: formatDescription(data?.value, data?.id)
            }}
          />
        )}
        {data?.dataformat === 'list' && (
          <Stack display='flex'>
            {data?.value?.map((item: string, index: number) => (
              <Stack key={item} display='flex' flexDirection='column'>
                <Typography variant='body2' sx={styles.accordionText}>
                  {item}
                </Typography>
                {index < data.value.length - 1 && data?.value?.length > 1 && (
                  <Divider sx={styles.diseaseDivider} />
                )}
              </Stack>
            ))}
          </Stack>
        )}
        {data?.dataformat === 'chips' && (
          <Box gap={1.5} display='flex' flexDirection='row' flexWrap='wrap'>
            {data?.value?.map((item: any) => (
              <Stack key={item?.id} display='flex' flexDirection='row'>
                <Box sx={styles.chipContainer}>
                  <Typography sx={styles.metadataText}>{item?.label}</Typography>
                </Box>
              </Stack>
            ))}
          </Box>
        )}
        {!data?.value ||
          (Array.isArray(data?.value) && data?.value.length === 0 && (
            <Typography variant='body2' sx={styles.accordionText}>
              No Data Available
            </Typography>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(IndicationAccordion);

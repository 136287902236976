import React from 'react';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import styles from './styles/IndicationChangeTimelineView.styles';
import IndicationChangeIcon from '../../../assets/svgs/Reg360/IndicationChangeIcon';
import ApprovalDateIcon from '../../../assets/svgs/CardsPage/ApprovalDateIcon';

const IndicationBadge = ({
  data,
  indication,
  activeBadge,
  isExpandedCardOpen,
  setActiveBadge,
  setExpandedPayload,
  setIsExpandedCardOpen,
  setAnchorEl,
  showApprovalDate = false
}: any) => {
  const handleBadgeClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    badgeKey: string,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setIsExpandedCardOpen(true);
    }, 200);
    setActiveBadge(badgeKey);
    setExpandedPayload({ ...item, indication });
  };

  return (
    <Box sx={styles.plusStyle} key={indication}>
      {showApprovalDate && (
        <Tooltip title={`Approval date: ${data?.approval_date?.[indication]?.approval_date}`}>
          <Box sx={{ ...styles.badgeContainer, top: '28%' }}>
            <ApprovalDateIcon />
          </Box>
        </Tooltip>
      )}
      <Box
        sx={{
          ...styles.badgeContainer,
          top: showApprovalDate ? '10%' : '28%'
        }}>
        {!showApprovalDate &&
          data?.indication_data?.[indication]?.map((item: any) => {
            const badgeKey = `${indication}-${item?.submission}`;
            const isSelected = activeBadge === badgeKey && isExpandedCardOpen;
            return (
              <Badge sx={styles.badgeStyle} key={badgeKey}>
                <Tooltip title={item?.submission}>
                  <IconButton
                    sx={{
                      ...styles.iconButton,
                      backgroundColor: isSelected ? 'secondary.900' : 'secondary.darkVariant',
                      boxShadow: isSelected ? 3 : 0,
                      transform: isSelected ? 'scale(1.3)' : 'none'
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      handleBadgeClick(event, badgeKey, item);
                    }}>
                    <IndicationChangeIcon sx={styles.indicationChangeIcon} />
                  </IconButton>
                </Tooltip>
              </Badge>
            );
          })}
      </Box>
    </Box>
  );
};

export default React.memo(IndicationBadge);

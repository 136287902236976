import TimelineColumnInterface from '../../../interfaces/TimelineColumnInterface';
import TimelineDate from '../../../components/Timeline/TimelineDate/TimelineDate';
import TimelineArray from '../../../components/Timeline/TimelineArray/TimelineArray';
import TimelineMore from '../../../components/Timeline/TimelineMore/TimelineMore';
import TimelineDocuments from '../../../components/Timeline/TimelineDocuments/TimelineDocuments';
import TimelineApprovalTime from '../../../components/Timeline/TimelineApprovalTime/TimelineApprovalTime';
import TimelineAvatars from '../../../components/Timeline/TimelineAvatars/TimelineAvatars';
import TimelineInformation from '../../../components/Timeline/TimelineInformation/TimelineInformation';
import PrimaryCompletionDateComparison from '../CTGraphs/PrimaryCompletionDateComparison';
import RecruitmentStatusComparison from '../CTGraphs/RecruitmentStatusComparison';
import { FILTER_TYPE } from '../../../components/NewFilters/constants';
import IndicationChange from '../IndicationChange/IndicationChange';

export const COLUMNS_US: TimelineColumnInterface[] = [
  {
    width: 1,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    tooltip: 'Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Type',
    tooltip: 'Submission Type',
    data: 'submission_type',
    name: 'submission_type',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['submission_type', 'description']
  },
  {
    width: 2.5,
    header: 'Action Details',
    data: 'change',
    name: 'change',
    tooltip: 'Action Details',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2.5,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2.5,
    header: 'Indications Change(s)',
    data: 'indication_change',
    name: 'indication_change',
    tooltip: 'Indications Changes(s)',
    rendercomponent: (
      <IndicationChange
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Time',
    data: 'approval_time',
    name: 'approval_time',
    tooltip: 'Approval Time (Days)',
    rendercomponent: (
      <TimelineApprovalTime
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1.5,
    header: 'Approval Tracks',
    data: 'approval_history',
    name: 'approval_history',
    tooltip: 'Approval Tracks',
    rendercomponent: (
      <TimelineAvatars
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];
// Change the EU
export const COLUMNS_EU: TimelineColumnInterface[] = [
  {
    width: 1.3,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    tooltip: 'Approval Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Type',
    data: 'submission_type',
    name: 'submission_type',
    tooltip: 'Submission Type',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['submission_type', 'description']
  },
  {
    width: 3.8,
    header: 'Action Details',
    data: 'change',
    name: 'change',
    tooltip: 'Action Details',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1.5,
    header: 'Rapporteur',
    data: 'rapporteur',
    name: 'rapporteur',
    rendercomponent: (
      <TimelineInformation
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_CT: TimelineColumnInterface[] = [
  {
    width: 2,
    header: 'Submitted Date',
    data: 'submitted_date',
    name: 'submitted_date',
    tooltip: 'Submitted Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 4,
    header: 'Changes',
    data: 'changes',
    name: 'changes',
    tooltip: 'Changes',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 3,
    header: 'Primary Completion Date',
    data: 'primary_completion_date',
    name: 'primary_completion_date',
    tooltip: 'Primary Completion Date',
    rendercomponent: (
      <PrimaryCompletionDateComparison
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 3,
    header: 'Recruitment Status',
    data: 'recruitment_status',
    name: 'recruitment_status',
    tooltip: 'Recruitment Status',
    rendercomponent: (
      <RecruitmentStatusComparison
        dataItem={undefined}
        column={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_JP: TimelineColumnInterface[] = [
  {
    width: 1.3,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    tooltip: 'Approval Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Submission Type',
    data: 'submission_type',
    name: 'submission_type',
    tooltip: 'Submission Type',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['submission_type']
  },
  {
    width: 2,
    header: 'Package Insert Number',
    data: 'package_insert_number',
    name: 'package_insert_number',
    tooltip: 'Package Insert Number',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['package_insert_number']
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 3,
    header: 'Indications',
    data: 'indications_and_usage',
    name: 'indications_and_usage',
    tooltip: 'Indications',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Remarks',
    data: 'remarks',
    name: 'remarks',
    tooltip: 'Remarks',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_CA: TimelineColumnInterface[] = [
  {
    width: 1,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    tooltip: 'Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1.5,
    header: 'Type',
    tooltip: 'Submission Type',
    data: 'submission_type',
    name: 'submission_type',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['submission_type', 'submission_class_descriptor']
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Submission Activity',
    data: 'summ_activity',
    name: 'summ_activity',
    tooltip: 'Submission Activity',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2.5,
    header: 'Decision',
    data: 'decision',
    name: 'decision',
    tooltip: 'Decision',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['decision', 'reason_decision']
  },
  {
    width: 2.5,
    header: 'Purpose',
    data: 'purpose',
    name: 'purpose',
    tooltip: 'Purpose',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Approval Tracks',
    data: 'approval_history_ca',
    name: 'approval_history_ca',
    tooltip: 'Approval Tracks',
    rendercomponent: (
      <TimelineAvatars
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_AU: TimelineColumnInterface[] = [
  {
    width: 1.5,
    header: 'Last Modified Date',
    data: 'revision_date',
    name: 'revision_date',
    tooltip: 'Last Modified Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 8,
    header: 'Indications',
    data: 'indications_and_usage',
    name: 'indications_and_usage',
    tooltip: 'Indications',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_CDRH: TimelineColumnInterface[] = [
  {
    width: 1,
    header: 'Date',
    data: 'decision_date',
    name: 'decision_date',
    tooltip: 'Approval Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1.5,
    header: 'Type',
    tooltip: 'Submission Type',
    data: 'submission_type',
    name: 'submission_type',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['submission_type']
  },
  {
    width: 1.5,
    header: 'Date of Received',
    data: 'date_received',
    name: 'date_received',
    tooltip: 'Date of Received',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Time',
    data: 'approval_time',
    name: 'approval_time',
    tooltip: 'Approval Time (Days)',
    rendercomponent: (
      <TimelineApprovalTime
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2.5,
    header: 'Reason',
    data: 'submission_reason',
    name: 'submission_reason',
    tooltip: 'Reason',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1.7,
    header: 'Approval Tracks',
    data: 'approval_history_us_devices',
    name: 'approval_history_us_devices',
    tooltip: 'Approval Tracks',
    rendercomponent: (
      <TimelineAvatars
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_HPRA: TimelineColumnInterface[] = [
  {
    width: 1,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    tooltip: 'Approval Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Type',
    tooltip: 'Submission Type',
    data: 'submission_type',
    name: 'submission_type',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['submission_type']
  },
  {
    width: 2,
    header: 'Category',
    tooltip: 'Category',
    data: 'category_bucket',
    name: 'category_bucket',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['category_bucket']
  },
  {
    width: 2,
    header: 'Title',
    tooltip: 'Document Title',
    data: 'document_title',
    name: 'document_title',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['document_title']
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_HMA: TimelineColumnInterface[] = [
  {
    width: 1,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    tooltip: 'Approval Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Category',
    tooltip: 'Category',
    data: 'category_bucket',
    name: 'category_bucket',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['category_bucket']
  },
  {
    width: 2,
    header: 'Title',
    tooltip: 'Document Title',
    data: 'document_title',
    name: 'document_title',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['document_title']
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_EUA: TimelineColumnInterface[] = [
  {
    width: 1,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    tooltip: 'Approval Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Last Updated Date',
    data: 'last_updated_date',
    name: 'last_updated_date',
    tooltip: 'Last Updated Date',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Attributes',
    data: 'attributes',
    name: 'attributes',
    tooltip: 'Attributes',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Authorized Setting',
    data: 'authorized_setting',
    name: 'authorized_setting',
    tooltip: 'Authorized Setting',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 1,
    header: 'Precode Assigned',
    data: 'precode_assigned',
    name: 'precode_assigned',
    tooltip: 'Precode Assigned',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const COLUMNS_MHRA: TimelineColumnInterface[] = [
  {
    width: 1,
    header: 'Date',
    data: 'approval_date',
    name: 'approval_date',
    tooltip: 'Approval Date',
    rendercomponent: (
      <TimelineDate
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 2,
    header: 'Category',
    tooltip: 'Category',
    data: 'category_bucket',
    name: 'category_bucket',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['category_bucket']
  },
  {
    width: 2,
    header: 'Title',
    tooltip: 'Document Title',
    data: 'document_title',
    name: 'document_title',
    rendercomponent: (
      <TimelineArray
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    ),
    concatColumns: ['document_title']
  },
  {
    width: 1,
    header: 'Resources',
    data: 'file_links',
    name: 'file_links',
    tooltip: 'Resources',
    rendercomponent: (
      <TimelineDocuments
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  },
  {
    width: 6,
    header: 'Indications',
    data: 'indications_and_usage',
    name: 'indications_and_usage',
    tooltip: 'Indications',
    rendercomponent: (
      <TimelineMore
        column={undefined}
        dataItem={undefined}
        triggeredBy='placeholder'
        source='placeholder'
      />
    )
  }
];

export const US_FILTERS = [
  {
    label: 'Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    label: 'Submission Type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_type',
    elasticSearchType: 'submission_type',
    showInBottomFilter: true
  },
  {
    label: 'Submission Class',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'description',
    elasticSearchType: 'submission_class_code_description',
    showInBottomFilter: true
  },
  {
    label: 'Review Priority',
    value: 'review_status',
    elasticSearchType: 'review_priority',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    label: 'Accelerated Approval',
    filterType: FILTER_TYPE.RADIO,
    value: 'accelerated_approval_flag',
    elasticSearchType: 'accelerated_approval_flag',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    showInBottomFilter: true
  },
  {
    label: 'Breakthrough',
    filterType: FILTER_TYPE.RADIO,
    value: 'bt_status',
    elasticSearchType: 'bt_status',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    showInBottomFilter: true
  },
  {
    label: 'Fast Track',
    filterType: FILTER_TYPE.RADIO,
    elasticSearchType: 'fast_track_flag',
    value: 'fast_track_flag',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    showInBottomFilter: true
  },
  {
    label: 'Orphan Status',
    filterType: FILTER_TYPE.RADIO,
    elasticSearchType: 'orphan_status',
    value: 'orphan_status',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    showInBottomFilter: true
  },
  {
    label: 'Documents',
    filterType: FILTER_TYPE.RADIO,
    value: 'documents',
    elasticSearchType: 'documents',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    showInBottomFilter: true
  },
  {
    label: 'RTOR',
    filterType: FILTER_TYPE.RADIO,
    elasticSearchType: 'rtor_flag',
    value: 'rtor_flag',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    showInBottomFilter: true
  },
  {
    label: 'Boxed Warning',
    filterType: 'radio_group',
    elasticSearchType: 'submission_boxed_warning_flag',
    value: 'submission_boxed_warning_flag',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    showInBottomFilter: true
  }
];

export const EU_FILTERS = [
  {
    label: 'Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    label: 'Submission Type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_type',
    elasticSearchType: 'submission_type',
    showInBottomFilter: true
  },
  {
    label: 'Documents',
    filterType: FILTER_TYPE.RADIO,
    value: 'documents',
    elasticSearchType: 'documents',
    showInBottomFilter: true,
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ]
  }
];

export const HPRA_FILTERS = [
  {
    label: 'Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    label: 'Submission Type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_type',
    elasticSearchType: 'submission_type',
    showInBottomFilter: true
  }
];

export const HMA_FILTERS = [
  {
    value: 'approval_date',
    label: 'Approval Date',
    filterType: FILTER_TYPE.DATE_RANGE,
    elasticSearchType: 'approval_date',
    showInBottomFilter: true
  },
  {
    value: 'category_bucket',
    label: 'Category',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    elasticSearchType: 'category',
    showInBottomFilter: true
  },
  {
    value: 'product_number',
    label: 'Product Number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    elasticSearchType: 'product_number',
    showInBottomFilter: true
  }
];

export const AU_FILTERS = [
  {
    label: 'Revision Date',
    value: 'revision_date',
    elasticSearchType: 'revision_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  }
];

export const CA_FILTERS = [
  {
    label: 'Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    label: 'Submission Type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_type',
    elasticSearchType: 'submission_type',
    showInBottomFilter: true
  },
  {
    label: 'Submission Class Descriptor',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_class_descriptor',
    elasticSearchType: 'submission_class_descriptor',
    showInBottomFilter: true
  },
  {
    label: 'Submission Number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_num',
    elasticSearchType: 'submission_num',
    showInBottomFilter: true
  },
  {
    label: 'NOC with Condition',
    filterType: FILTER_TYPE.RADIO,
    elasticSearchType: 'noc_w_condition_flag',
    value: 'noc_w_condition_flag',
    showInBottomFilter: true,
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ]
  },
  {
    label: 'Priority Review',
    filterType: FILTER_TYPE.RADIO,
    elasticSearchType: 'priority_review_flag',
    value: 'priority_review_flag',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ],
    showInBottomFilter: true
  }
];

export const CT_FILTERS_REG360 = [
  {
    label: 'Submission Date',
    value: 'submitted_date',
    elasticSearchType: 'submitted_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    label: 'Changes',
    value: 'changes',
    elasticSearchType: 'changes',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  }
];

export const CDRH_FILTERS = [
  {
    label: 'Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    label: 'Received Date',
    filterType: FILTER_TYPE.DATE_RANGE,
    elasticSearchType: 'date_received',
    value: 'date_received',
    showInBottomFilter: true
  },
  {
    label: 'Submission Type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_type',
    elasticSearchType: 'submission_type',
    showInBottomFilter: true
  },
  {
    label: 'Submission Number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_num',
    elasticSearchType: 'submission_num',
    showInBottomFilter: true
  }
];

export const EUA_FILTERS = [
  {
    label: 'Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    label: 'Last Updated Date',
    filterType: FILTER_TYPE.DATE_RANGE,
    elasticSearchType: 'last_updated_date',
    value: 'last_updated_date',
    showInBottomFilter: true
  },
  {
    label: 'Submission Number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'submission_num',
    elasticSearchType: 'submission_num',
    showInBottomFilter: true
  }
];

export const JP_FILTERS = [
  {
    value: 'approval_date',
    label: 'Approval Date',
    filterType: FILTER_TYPE.DATE_RANGE,
    elasticSearchType: 'date',
    showInBottomFilter: true
  },
  {
    value: 'submission_type',
    label: 'Submission Type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    elasticSearchType: 'type',
    showInBottomFilter: true
  },
  {
    value: 'package_insert_number',
    label: 'Package Insert Number',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    elasticSearchType: 'package_insert_number',
    showInBottomFilter: true
  }
];

export const MHRA_FILTERS = [
  {
    label: 'Approval Date',
    value: 'approval_date',
    elasticSearchType: 'approval_date',
    filterType: FILTER_TYPE.DATE_RANGE,
    showInBottomFilter: true
  },
  {
    label: 'Category',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    value: 'category_bucket',
    elasticSearchType: 'document_type',
    showInBottomFilter: true
  }
];

export const TIMELINE_COLUMNS: { [key: string]: TimelineColumnInterface[] } = {
  us: COLUMNS_US,
  eu: COLUMNS_EU,
  ct: COLUMNS_CT,
  ca: COLUMNS_CA, // PMA, PMN and Denovo has same columns
  pma: COLUMNS_CDRH,
  pmn: COLUMNS_CDRH,
  denovo: COLUMNS_CDRH,
  eua: COLUMNS_EUA,
  hpra: COLUMNS_HPRA,
  hma: COLUMNS_HMA,
  jp: COLUMNS_JP,
  au: COLUMNS_AU,
  mhra: COLUMNS_MHRA
};

export const APPROVAL_HISTORY = 'approval_history';

export default COLUMNS_US;

export const COLOR_SCHEME: { [key: string]: string } = {
  'Phase 1': '#84a98c',
  'Phase 2': '#52796f',
  'Phase 3': '#354f52',
  'Phase 4': '#2f3e46'
};

export const ENTITY_DATA_MAPPING: { [key: string]: any } = {
  nct_id: { name: 'NCT ID' },
  start_date: { name: 'Start Date', getValue: (val: string) => val?.split('T')[0] || val },
  completion_date: { name: 'End Date', getValue: (val: string) => val?.split('T')[0] || val },
  phase: { name: 'Phase' }
};

export const SOURCE_RESOURCE_MAPPPING: { [key: string]: string } = {
  us: 'SBAs',
  eu: 'EPARs',
  ca: 'HPFBs',
  'us-devices': 'SBAs',
  hpra: 'HPRAs',
  hma: 'HMAs',
  jp: 'PMDAs',
  au: 'ARTGs',
  mhra: 'MHRAs'
};

export const SOURCE_MODULE_MAPPING_FOR_ARIA: { [key: string]: any } = {
  us: { us: ['sba'] },
  eu: { eu: ['epar'] },
  'us-devices': { us: ['devices'] },
  ct: { ct: ['usnlm'] },
  ca: { ca: ['hpfb'] },
  hpra: { ie: ['hpra'] },
  hma: { eu: ['hma'] },
  jp: { jp: ['pmda'] },
  au: { au: ['tga'] },
  mhra: { uk: ['mhra'] }
};

export const RESOURCE_MAPPPING_BAESD_ON_TABNAME: Array<string> = ['commissionProcedures'];

import { Box, Divider, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import CloseCrossIcon from '../../../assets/svgs/Icons/CloseCrossIcon';
import styles from './styles/IndicationChangeDrawer.styles';

import Metadata from '../../../components/Dialogs/DocumentListing/components/EntityMetadata';

import IndicationAccordion from './IndicationAccordion';

export interface IndicationChangeDrawerProps {
  onClose: () => void;
  selectedEntityId: string;
  // eslint-disable-next-line react/require-default-props
  subEntities?: Array<any>;
  indicationData: Array<any>;
}
const IndicationChangeDrawer = ({
  selectedEntityId,
  subEntities = [],
  indicationData = [],
  onClose
}: IndicationChangeDrawerProps) => {
  const selectedEntity = useMemo(() => {
    return subEntities?.find((entity: any) => entity.id === selectedEntityId);
  }, [selectedEntityId]);
  return (
    <Drawer
      open
      anchor='right'
      transitionDuration={500}
      hideBackdrop={false}
      sx={styles.mainDrawer}
      onClose={onClose}>
      <Box bgcolor='primary.600' py={1.5} px={2.5}>
        <Stack direction='row' alignItems='center'>
          <Typography sx={styles.drawerTitle}>Indications</Typography>

          <IconButton sx={styles.crossIcon} onClick={onClose}>
            <CloseCrossIcon sx={styles.drawerHeaderIcon} />
          </IconButton>
        </Stack>
      </Box>
      <Box height='100%'>
        <Box px={2.5} py={1.5}>
          <Stack direction='row' alignItems='center'>
            <Box display='flex' alignItems='center' maxWidth='30%'>
              <Tooltip title={selectedEntity?.name}>
                <Typography
                  color='gray.900'
                  fontWeight={700}
                  fontSize={14}
                  lineHeight={1.5}
                  overflow='hidden'
                  textOverflow='ellipsis'
                  mt={0.25}
                  sx={{ textWrap: 'nowrap' }}>
                  {selectedEntity?.name}
                </Typography>
              </Tooltip>
            </Box>
            {selectedEntity?.metadata && selectedEntity?.metadata?.length > 0 && (
              <>
                <Divider
                  orientation='vertical'
                  sx={{ height: 16, mx: 1.5, borderColor: 'gray.400' }}
                />
                <Metadata data={selectedEntity?.metadata} />
              </>
            )}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: 'gray.200' }} />
        <Box px={2.5} py={2}>
          <Typography sx={{ ...styles.headingText, fontWeight: 400 }}>
            Following are{' '}
            <span style={{ ...styles.headingText, fontWeight: 700 }}>
              All Indications, Indications Change(s), Changed Disease Types and Therapeutic Use
            </span>{' '}
            details:
          </Typography>
        </Box>
        <Box px={2.5} py={2} sx={styles.accordionContainer}>
          {indicationData?.map((data: any) => (
            <IndicationAccordion key={data.id} data={data} />
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default React.memo(IndicationChangeDrawer);
